export function initialize_selectize() {
  // 5/3/23: Going to remove ".not(".n-group select[multiple]")" for now since this is causing a bug inside the edit profile form

  $.each($('select[multiple]').not('.ngroup-template select[multiple]'), function(index, elem) {
    // Don't initialize if the element already has a selectize attached
    if ($(elem).data('auto-initialize') != '0') {
      $(elem).selectize({
        plugins: ['remove_button', 'selectize-plugin-a11y'],
        closeAfterSelect: true,
        maxItems: function(input) {
          return input.data("max-items");
        },
        onChange: function() {
          var event = new CustomEvent('change', {bubbles: true, cancelable: true})
          this.$input[0].dispatchEvent(event);
        }
      });
    }
  });

  $.each($('select[data-selectize]').not('.ngroup-template select[data-selectize]'), function(index, elem) {
    $(elem).selectize();
  });

  $('select.type-ahead').not(".ngroup-template select.type-ahead").selectize();

  $('form').on('click', '.selectize-input', function() {
    var s = $(this).parent().prev()[0].selectize;
    s.open();
  });

  $('.selectize-input input[type="text"]').attr({'aria-label': "option"});
  $('.selectize-input .remove').attr('aria-label', 'remove');
}
